<template>
  <div class="ayl_video">
    <!-- video 视频的播放按钮默认不会在中间，需要加上vjs-big-play-centered -->
    <video ref="videoPlayer" class="video-js vjs-big-play-centered"></video>
  </div>
</template>
<script>

  import './video.js'
  import video_zhCN from '@utils/zh-CN.json'

  export default {
    props: {
      url: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        player: null
      }
    },
    watch: {
      // 监听
      'url': function () {
        this.initVideo()
      }
    },
    methods: {
      initVideo () {
        // eslint-disable-next-line no-undef
        this.player = videojs(this.$refs.videoPlayer, {
          autoplay: true,
          controls: true,
          muted: true,
          language: 'zh-CN',
          aspectRatio: '16:9',
          sources: [
            {
              src: this.url,
              type: 'video/mp4'
            }
          ],
        }, function onPlayerReady () {
          // console.log('onPlayerReady', this);
        })
        // eslint-disable-next-line no-undef
        videojs.addLanguage('zh-CN', video_zhCN)
      },
    },
    mounted () {
      if (!this.url) return
      this.initVideo()
    },
    // https://docs.videojs.com/docs/guides/removing-players.html
    // https://vuejs.org/v2/api/#beforeDestroy
    beforeDestroy () {
      if (this.player) {
        this.player.dispose()
      }
    }
  }
</script>
<style scoped lang="scss">
  // .ayl_video {
  //   width: 100%;
  //   height: 100%;
  // }
</style>
